import Image from 'components/atoms/Image/Image';
import React, { FC } from 'react';
import './../../scss/components/Comparison.scss';
import { ComparisonProps } from './Comparison.model';

export const Comparison: FC<ComparisonProps> = ({
  id,
  title,
  subtitle,
  headers,
  showHeaderTitle = true,
  rows,
  imageMapping = {},
}) => {
  return (
    <section className="mt-40 comparison" id={id}>
      <div className="text-center mb-6">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          {title}
        </h2>
        {subtitle && (
          <div className="flex justify-center">
            <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[43rem]">
              {subtitle}
            </p>
          </div>
        )}
      </div>

      <div className="rounded-lg shadow-testimonials comparison-table">
        <div className="rounded-t-lg bg-base-100 comparison-table__header">
          <div className="comparison-table__empty-space"></div>
          <div className="comparison-table__header-row">
            {headers.map((header, idx) => (
              <div key={idx} className="comparison-table__header-item">
                {imageMapping[header] && (
                  <Image
                    className="comparison-table__header-item-image"
                    name={imageMapping[header]}
                  />
                )}
                {showHeaderTitle && (
                  <div className="comparison-table__header-item-name">{header}</div>
                )}
              </div>
            ))}
          </div>
        </div>

        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="pl-8 md:pl-0 comparison-table__row">
            <span className="comparison-table__row-title">{row.title}</span>
            <div className="comparison-table__row-checks">
              {row.values.map((value, valueIndex) => (
                <>
                  {typeof value === 'boolean' ? (
                    <Image
                      className="comparison-table__row-image"
                      name={value ? 'check-mark-grey' : 'cancel-grey'}
                    />
                  ) : (
                    <span className="comparison-table__custom-value">{value}</span>
                  )}
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
